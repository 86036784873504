<template>
  <div class="txVideo-page">
    <div id="video_box" ref="video_box" class="video_box" />
    <div v-show="isShowControl" class="video-header">
      <slot />
    </div>
  </div>
</template>
<script>
function loadTcScript(cb) {
  loadScript(
    function () {
      loadScript(cb, {
        id: 'tcPlayerScriptId',
        url: 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.5.0/tcplayer.v4.5.0.min.js'
      })
    },
    {
      id: 'hlsScriptId',
      url: 'https://web.sdk.qcloud.com/player/tcplayer/release/v4.5.0/libs/hls.min.0.13.2m.js'
    }
  )
}

function loadScript(cb, obj) {
  if (document.getElementById(obj.id)) {
    cb()
  } else {
    const script = document.createElement('script')
    script.async = true
    script.src = obj.url
    script.id = obj.id
    script.onload = () => {
      cb()
    }
    document.body.appendChild(script)
  }
}
export default {
  name: 'TencentPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tcPlayerId: 'tcPlayer' + Date.now(),
      player: null, // 播放器对象
      currentTime: 0, // 当前播放时间点
      isLoadedmetadata: false, // 播放器是否加载完毕
      isShowControl: true // 是否显示控制面板
    }
  },
  watch: {
    options: {
      handler(newV, oldV) {
        this.$nextTick(() => {
          this.loadJS()
        })
      },
      deep: true
    }
  },
  created() {
    this.loadJS()
    this.initVideo()
  },
  destroyed() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    loadJS() {
      if (window.TCPlayer) {
        this.initVideo()
      } else {
        loadTcScript(() => {
          console.log('window.TCPlayer', window.TCPlayer)
          this.initVideo()
        })
      }
    },
    initVideo() {
      const playerParm = {
        fileID: this.options.file_id,
        appID: this.options.appId,
        autoplay: false,
        playbackRate: [0.5, 1, 1.25, 1.5, 2],
        plugins: {
          // VttThumbnail: {
          //   imgUrl: "//1304134186.vod2.myqcloud.com/1908a188vodsh1304134186/2e30bbdb387702295251066977/387702296453021581.jpg"
          // },
          ContinuePlay: {
            // 开启续播功能
            // auto: true, //[可选] 是否在视频播放后自动续播
            // text:'上次播放至 ', //[可选] 提示文案
            // btnText: '恢复播放' //[可选] 按钮文案
          }
        }
        // controlBar:{
        // 	durationDisplay:false,
        // 	currentTimeDisplay:false,
        // },
        // controls:false,
        // psign: this.options.media_url,
      }
      setTimeout(() => {
        if (!this.player) {
          console.log('createVideo')
          this.createVideo(playerParm)
        } else {
          this.isLoadedmetadata = false
          console.log('this.isLoadedmetadata', this.isLoadedmetadata)
          console.log('playerParm', playerParm)
          this.player.loadVideoByID(playerParm)
        }
      }, 100)
    },
    createVideo(playerParm) {
      const el = document.getElementById('video_box')
      const video = document.createElement('video')
      video.id = this.tcPlayerId
      video.setAttribute('playsinline', true)
      video.setAttribute('webkitPlaysinline', true)
      video.setAttribute('x5-playsinline', true)
      video.setAttribute('controlsList', 'nodownload')
      video.setAttribute('uc-video-toolbar-id', '121212')
      video.preload = 'auto'

      el.appendChild(video)

      this.$nextTick(function () {
        // eslint-disable-next-line no-undef
        this.player = TCPlayer(this.tcPlayerId, playerParm)

        this.player.on('ended', () => {
          this.$emit('videoEnded')
          this.isShowControl = true
        })

        this.player.on('pause', () => {
          this.isShowControl = true
        })

        this.player.on('timeupdate', () => {
          this.currentTime = this.player.currentTime()
        })

        this.player.on('loadedmetadata', () => {
          this.isLoadedmetadata = true
          console.log('加载完成')
        })
      })
    },
    /*
     * cs 2020-6-3
     *  暂停
     */
    pause() {
      if (this.isLoadedmetadata) {
        console.log('暂停')
        if (this.player) {
          this.player.pause()
        }
      }
    },
    /*
     * cs 2020-6-3
     * 播放
     */
    play() {
      if (this.isLoadedmetadata) {
        this.player.play()
      }
    },
    /*
     * cs 2020-6-3
     * 销毁播放器
     */
    xiaohui() {
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
    },
    /*
     * cs 2020-8-6
     * 重新播放
     */
    rePlay() {
      this.loadJS()
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.css");

.txVideo-page {
  z-index: -1;
  width: 100%;
  height: 7.49rem;
  // position: relative;
}

.video_box {
  width: 100%;
  height: 7.49rem;
  // height: 500rpx;
}

.video_box ::v-deep .video-js {
  width: 100%;
  height: 7.49rem;
  max-width: 100%;
  padding-top: 56.25%;
}

.video-header {
  color: #fff;
  position: absolute;
  width: 100%;
  // padding: 30rpx 20rpx;
  z-index: 99;
  top: 0;
  left: 0;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}
video::-webkit-media-controls-panel {
  width: calc(100% + 0.2rem);
}
</style>
