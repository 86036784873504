<template>
  <div class="pro_del_bar flex-r-sa-c">
    <div class="left_btn flex-r-sa-c">
      <div class="right_btn flex-r-s-s">
        <div v-if="status === 1" class="vip_btn_active" @click="subPro">{{ price }}元领</div>
        <div v-if="status === 2" class="vip_btn_no">会员等级不足</div>
        <div v-if="status === 3" class="vip_btn_no">已领过此商品</div>
        <div v-if="status === 4" class="vip_btn_no">来晚啦，已经领完啦</div>
        <div v-if="status === 5" class="vip_btn_no">本月领取次数已用完</div>
      </div>
      <!-- 客服组件 -->
      <kefuBtn ref="mykefuBtn" />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['info', 'companyId', 'price', 'status'],
  data() {
    return {
    }
  },
  methods: {
    subPro() {
      // 合并数据
      // 跳转参数
      let url = ''
      url = `/order/subOrder?item=${this.info}-1-${this.companyId}&type=4`
      // 参数不为空
      if (url !== '') {
        this.$router.push(url)
      }
    }
  }
}
</script>

<style lang="scss">
.pro_del_bar {
  position: fixed;
  width: 10rem;
  height: 1.71rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 0rem;
  bottom: 0;
  .left_btn {
    width: 30%;
    .left_btn_item {
      font-size: 0.52rem;
      color: #686868;
    }
    span {
      font-size: 0.32rem;
      font-weight: 400;
      color: #686868;
    }
  }
  .right_btn {
    font-size: 0.37rem;
    line-height: 1.17rem;
    color: #ffffff;
    .right_btn_cart {
      width: 2.8rem;
      height: 1.17rem;
      background: #0767ab;
      border-top-left-radius: 0.59rem;
      border-bottom-left-radius: 0.59rem;
    }
    .right_btn_pay {
      width: 2.8rem;
      height: 1.17rem;
      background: #d1531c;
      border-top-right-radius: 0.59rem;
      border-bottom-right-radius: 0.59rem;
    }
    .right_btn_seckill {
      width: 5.6rem;
      height: 1.17rem;
      background: #d1531c;
      border-radius: 0.59rem;
    }
    .vip_btn_active {
      font-size: 0.48rem;
      color: #673b15;

      width: 7.79rem;
      height: 1.17rem;
      border-radius: 0.59rem;
      background: #f3d1ac;
    }
    .vip_btn_no {
      font-size: 0.48rem;
      color: #ffffff;
      width: 7.79rem;
      height: 1.17rem;
      border-radius: 0.59rem;
      background: #cfcfcf;
    }
  }
}
</style>
